html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #000000;
  color: lightgray;
}

a {
  text-decoration: none;
  color: inherit;
}

@mixin mobile {
  @media (max-width: 738px) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin desktop {
  @media (max-width: 1366px) {
    @content;
  }
}
