@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35)); */
}
