@import "../../app.scss";

.portfolio {
  position: relative;

  section {
    height: 100vh;
    width: 100vw;
    scroll-snap-align: center;
    overflow: hidden;
  }

  body {
    background-color: #000000;
    color: lightgray;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @mixin mobile {
    @media (max-width: 738px) {
      @content;
    }
  }
  @mixin tablet {
    @media (max-width: 1024px) {
      @content;
    }
  }
  @mixin desktop {
    @media (max-width: 1366px) {
      @content;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .wrapper {
      max-width: 1366px;
      height: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;

      @include mobile {
        flex-direction: column;
      }

      .imageContainer {
        flex: 1;
        height: 50%;

        @include mobile {
          width: 100%;
          max-height: 300px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          @include mobile {
            object-fit: contain;
          }
        }
      }

      .textContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;

        @include mobile {
          transform: none !important;
          padding: 10px;
          align-items: center;
          text-align: center;
        }

        h2 {
          font-size: 72px;

          @include mobile {
            font-size: 36px;
          }
        }

        p {
          color: gray;
          font-size: 20px;

          @include mobile {
            font-size: 16px;
          }
        }

        button {
          background-color: orange;
          border: none;
          border-radius: 10px;
          padding: 10px;
          width: 200px;
          cursor: pointer;
        }
      }
    }
  }
}
